.sort-controls {
	position: relative;

	.up {
		top: 7px;
		right: -15px;
		position: absolute;
	}

	.down {
		top: 14px;
		right: -15px;
		position: absolute;
	}
}

.table tr {
	font-size: 15px;
	line-height: 19px;
	font-weight: normal;
}

.table thead th {
	font-size: 15px;
}

.table thead th {
	border-top: none;
	border-bottom: none;
	white-space: nowrap;
}

.table tr {
	font-size: 15px;
	line-height: 19px;
	font-weight: normal;
}

.table td {
	color: black;
	padding-top: 25px;
	padding-left: 15px;
	padding-bottom: 25px;
}

.table th {
	color: black;
	font-weight: 700;
	font-size: large;
	padding-left: 15px;
	padding: 0.65rem 0.7rem;
}

.page-item.active .page-link {
	z-index: 1;
	color: #fff;
	border-color: #0092fb;
	background-color: #0092fb;
}

.page-link {
	color: inherit;
}

.c-btn {
	color: #fff !important;
	border-radius: 2px !important;
	background: #0994f8 !important;
}

.modal-close,
.modal-close:focus {
	border: none;
	outline: none;
	background-color: transparent;
}

a:hover {
	border: none;
	outline: none;
	text-decoration: none;
}

.dcard_icond {
  display: flex;
	// flex: none;
  align-items: center;
  justify-content: center;
	width: 50px;
	height: 50px;
	// padding: 7px 15px;
	// margin-left: 35px;
	border-radius: 50%;
	text-align: center;
	background: #e6f8fc;
	// display: inline-block !important;
}

.dcard_imgd {
	width: 30px;
	height: 30px;
	// margin-top: 3px;
	// margin-left: -5px;
}

.card-title {
	color: black;
}

.circle-green {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #00cc00;
}

.circle-red {
	width: 10px;
	height: 10px;
	background: red;
	border-radius: 50%;
}

.circle-yellow {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #ffc107;
}

.editable-input {
	width: 40px;
	border: none;
	// padding: 8px;
	outline: none;
}

.editable-input:focus {
	width: 40px;
	// padding: 7px;
	border: 1px solid #dddddd;
}

.input-btn {
	height: 20px;
	font-size: 8px;
}

.search_btn {
	margin-top: 33px;
}

.digit {
	color: #040404;
	font-size: 1.4rem;
	font-weight: bold;
	margin-right: 10px;
}

.text-clr {
	color: #6182e2;
	white-space: nowrap;
}

.text-clr-multiple {
	color: #838383;
	white-space: nowrap;
}

.box {
	padding: 10px;
	cursor: pointer;
	max-width: 100%;
	border-radius: 5px;
	border: 1px solid #ebebeb;
	// background: lightgrey;
}

.box:hover {
	padding: 10px;
	max-width: 100%;
	border-radius: 5px;
	background: #c0cdf2;
	border: 1px solid #446ad7;
	.digit {
		color: #446ad7;
		font-size: 1.4rem;
		font-weight: bold;
		margin-right: 10px;
	}

	.text-clr {
		color: #ffffff;
		white-space: nowrap;
	}

	.text-clr-multiple {
		color: #ffffff;
		white-space: nowrap;
	}
}

.selected-filter {
	background: #c0cdf2;
	border: 1px solid #446ad7;

	.digit {
		color: #446ad7;
		font-size: 1.4rem;
		font-weight: bold;
		margin-right: 10px;
	}

	.text-clr {
		color: #ffffff;
		white-space: nowrap;
	}

	.text-clr-multiple {
		color: #ffffff;
		white-space: nowrap;
	}
}
