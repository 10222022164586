.email-view {
  font-size: 80%;
  position: fixed;
  right: -90%;
  top: 0px;
  bottom: 0;
  height: 100%;
  width: 35%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  box-shadow: -2px 0 3px rgba(0, 0, 0, 0.2);
  visibility: hidden;
  transition: all 0.2s linear !important;
  background-color: #fff;
  z-index: 1500;
}

.email-view-open {
  visibility: visible;
  right: 0;
  transition: all 0.2s linear;
}

.close-view {
  right: -90%;
  transition: all 0.2s linear !important;
}

#closeBtn {
  cursor: pointer;
}
#openBtn {
  color: #46affa !important;
  cursor: pointer !important ;
}
.main-container {
  padding: 100px 130px 100px 30px;
}
.logs {
  font-size: 18px;
  color: blueviolet;
  line-height: 40px;
}
.search_btn {
  margin-top: 30px;
}
.checkbox {
  margin-top: 40px;
}
.label-search {
  font-size: 0.9vw;
}
