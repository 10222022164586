.message {
	margin: 0;
	color: #ffffff;
	border-radius: 5px;
	padding: 2px 5px 1px;
	background: #345bcc;
}

#message-container-sender {
	margin: 0.1rem;
	max-width: 60%;
	width: fit-content;
	border-radius: 5px;
	min-height: 2.25rem;
	color: #ffffff;
	background: #345bcc;
	padding: 0.1rem 0.3rem;
	box-sizing: border-box;
}

#message-container-reciever {
	margin: 0.1rem;
	max-width: 60%;
	color: #ffffff;
	width: fit-content;
	border-radius: 5px;
	min-height: 2.25rem;
	background: #4895ef;
	padding: 0.3rem 0.3rem;
	box-sizing: border-box;
}
