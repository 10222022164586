.dropdown-jobs {
  width: 250px;
  padding: 10px;
}
.job-btn {
  background-color: #0994f8;
  padding: 10px;
  color: white;
  border-radius: 5px;
  margin: 0px 10px;
  font-size: 16px;
}
.job-btn:hover {
  color: white;
}
