.right-view {
	// min-width: 25vw;
	// visibility: hidden;
	// right: -90;
	position: fixed;
	right: -90%;
	visibility: hidden;
	transition: all 0.2s linear;
	z-index: 9999;
	background: #ffffff;
	height: 100vh;
	width: 35vw;
  padding: 10px;
}

.view-message-section {
	visibility: visible;
	right: 0;
	transition: all 0.2s linear;
	z-index: 9999;
}
