.main-headings span {
	font-size: 32px;
	font-family: Avenir Next;
}
.main-headings p {
	font-family: Avenir Next;
	font-size: 18px;
}
.header-bar {
	font-size: 15px;
	margin-left: 1px;
}
.icon-bg {
	width: 45px;
	height: 45px;
	background-color: #e2f1f4;
	padding: 7px 10px;
}
.provider-card,
.analyzer-card-row {
	border: 1px solid #e2f1f4;
}
// .analyzer-card {
//   box-shadow: 5px 2px #c7c6c6;
// }
.view-detail-button {
	padding: 5px 10px;
	height: auto !important;
	background-color: #0994f8;
	color: #fff;
	font-family: 'Avenir Next LT Pro' !important;
	font-style: normal !important;
	font-size: 0.9vw !important;
	line-height: 22px !important;
	outline: none !important;
	border-radius: 3px;
	border: none;
}
.text-style {
	font-family: 'Avenir Next LT Pro';
}
.form-description {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 37px;
	color: #a9a9a9;
}
.insight-card {
	min-height: 150px !important;
	display: flex;
	align-items: center;
}
.overallResult {
	font-size: 0.8vw;
	font-weight: bold;
}
.analyzer-card {
	border: none !important;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.smiley-face {
	font-size: 35px;
}
.analyzer-content {
	font-size: 0.8vw;
	font-family: 'Avenir Next LT Pro';
}

#accordion-btn {
	border: 1px solid;
}

.accordion-button::after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: '';
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.25rem;
	transition: transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	transform: rotate(-180deg);
}

.right-header {
	height: auto;
}
.btn-right-shareReport {
	margin-top: -12px;
}
.icon-div {
	height: 53px !important;
	width: 53px;
	background-color: #e2f1f4;
}
.accordion-icon {
	height: 32px !important;
	width: 32px;
	background-color: #e2f1f4;
	margin-left: 21px;
	margin-top: 10px;
	float: left;
}
.AccordionSection {
	width: 100%;
}
.AccContainer {
	width: 100%;
}
.Wrap {
	transition: all 0.2s linear !important;
	background: #fff;
	color: #3a3974;
	display: flex;
	height: auto;
	margin-top: 10px;
	margin-left: 7px;
	margin-right: 7px;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border-top: 0.3px solid #dee1e6;
	border-right: 0.3px solid #dee1e6;
	border-left: 0.3px solid #dee1e6;
	border-bottom: 0.1px solid #dee1e6;
}
.Wrap p {
	margin-top: 15px;
	margin-left: 15px;
	font-size: 1rem;
	float: left;
}
.accordion-right button {
	height: 24px;
	width: 58px;
	border: none;
	margin-right: 20px;
	border-radius: 12px;
	color: #fff;
	float: left;
}
.accordion-right b {
	margin-right: 20px;
	float: right;
}
.right-part {
	height: 50px;
}
.Dropdown {
	background: #fff;
	color: #3a3974;
	justify-content: center;
	align-items: center;
	margin-left: 7px;
	margin-right: 7px;
	border-top: 0.1px solid #dee1e6;
	border-right: 0.3px solid #dee1e6;
	border-left: 0.3px solid #dee1e6;
	border-bottom: 0.3px solid #dee1e6;
	transition: all 0.2s linear !important;
}
.content {
	padding: 20px;
	margin: 20px;
	height: auto;
	text-align: center;
	vertical-align: middle;
}
.bold-content {
	font-size: 1.1rem;
	font-weight: 600;
	text-transform: capitalize;
}
.modal-title-dns {
	background-color: #fff;
	padding: 10px;
	border-bottom: 1px solid rgb(243, 237, 237);
}
.modal-close {
	float: right;
	margin-top: -45px;
	margin-right: 10px;
	border: none;
	background-color: #fff;
	outline: none;
}

.modal-close-attachment {
	float: right;
	// margin-top: -45px;
	// margin-right: 10px;
	border: none;
	background-color: #fff;
	outline: none;
}
.modal-body-dns {
	padding: 20px;
}
.input-filed {
	width: 85%;
	height: 45px !important;
	border-radius: 3px;
	font-weight: bold !important;
}
.modal-button {
	width: 100px;
	height: 50px !important;
	background-color: #0994f8;
	color: #fff;
	font-family: 'Eudoxus Sans' !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 16px !important;
	line-height: 20px !important;
	outline: none !important;
	border-radius: 3px !important;
	border-color: #fff;
	margin-left: 5px !important;
}
.left_arrow img:hover {
	color: blue;
}

.topnav {
	background-color: #fff;
	overflow: hidden;
	margin-left: 23px;
	border: 0.3px solid #dee1e6;
	margin-right: 21px;
	margin-top: 20px;
}
.topnav_icon {
	height: 40px !important;
	width: 40px;
	background-color: #e2f1f4;
	float: left;
}

/* Style the links inside the navigation bar */
.topnav_button_fix {
	float: left;
	display: block;
	color: #acacac;
	text-align: center;
	padding: 10px 16px;
	text-decoration: none;
	font-size: 17px;
	border-bottom: 3px solid transparent;
	background: none;
	outline: none !important;
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
}
.topnav_button_raw {
	float: left;
	display: block;
	color: #acacac;
	text-align: center;
	padding: 10px 16px;
	text-decoration: none;
	font-size: 17px;
	border-bottom: 3px solid transparent;
	background: none;
	outline: none !important;
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
}

.topnav_button_active {
	float: left;
	display: block;
	text-align: center;
	padding: 10px 16px;
	text-decoration: none;
	font-size: 17px;
	background: none;
	outline: none !important;
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
	color: #000;
	border-bottom: 3px solid #0994f8;
}

.topnav_button_active :focus {
	outline: none !important;
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
}
.topnav_button_fix :focus {
	outline: none !important;
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
}
.topnav_button_raw :focus {
	outline: none !important;
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
}

.rawEmail {
	background-color: #fff;
	overflow: hidden;
	margin-left: 23px;
	border: 0.3px solid #dee1e6;
	margin-right: 20px !important;
	margin-top: 20px;
	padding-top: 8px;
	height: auto;
}
.topnav button:hover {
	border-bottom: 3px solid #0994f8;
}
.description {
	width: 100%;
}
.description td {
	padding: 15px;
	text-align: center;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;
	width: 50%;
}
.link {
	color: #123eb8;
	text-decoration: underline;
}
.link:hover {
	text-decoration: none;
	cursor: pointer;
}
.dns_sbg {
	display: inline-block;
	background: #e6f8fc;
	width: 230px;
	height: 230px;
	border-radius: 50%;
	padding-top: 30px;
	text-align: center;
}
.detected-by {
	font-family: 'Eudoxus Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
}
.fa-times-circle {
	font-size: 14px;
	color: red;
}
.fa-check-circle {
	font-size: 14px;
	color: green;
}
.dns_email_item {
	font-style: normal;
	// font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	color: #0994f8;
}
.truncate {
	width: 350px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.disabled-link {
	pointer-events: none;
}
.headingDns {
	font-size: 1.1rem;
	font-family: 'Eudoxus Sans';
	font-weight: 800;
	line-height: 20px;
	word-spacing: 4px;
}
.explaination {
	margin: 30px 20% 30px 8%;
	font-size: 14px;
	font-family: 'Eudoxus Sans';
	font-weight: 500;
	line-height: 30px;
	word-spacing: 3px;
}
.test-name {
	color: white;
	font-size: 20px;
	font-weight: 600;
	line-height: 20px;
	font-family: 'Eudoxus Sans';
	width: fit-content;
	margin: 30px 20% 0px 8%;
	padding: 10px;
	border-radius: 3px;
}
.test-description {
	margin: 18px 20% 30px 8%;
	font-size: 14px;
	font-family: 'Eudoxus Sans';
	font-weight: 500;
	line-height: 15px;
	word-spacing: 3px;
}
.score {
	font-size: 24px;
	font-weight: 700;
	color: #0092fb;
	margin: 3px;
}

