.first-icon {
	height: 45px;
	width: 45px;
	border-radius: 50%;
	border: 1px solid #e2f1f4;
}
.disabled-link {
	pointer-events: none;
}

// #payout-btn::before {
// 	content: '';
// 	flex-grow: 1000000;
// }
